import { LandingPage } from '@/modules/landingPage';
import { read } from '@/utils/file-helper';
import matter from 'gray-matter';
import React from 'react';

const Default = ({ news }) => {
  return <LandingPage news={news} />;
};

export async function getStaticProps() {
  const fileNames = read('/public/contents', 'dir');
  const blogPosts = [];

  for (const fileName of fileNames) {
    const rawContent = read(`/public/contents/${fileName}`, 'file') as string;

    const { data: frontmatter } = matter(rawContent);

    blogPosts.push({
      slug: fileName.replace('.mdx', ''),
      ...frontmatter,
    });
  }

  return {
    props: {
      news: blogPosts.sort((p1, p2) => (p1.date < p2.date ? 1 : -1)),
    },
  };
}

export default Default;
